<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0" >
        <span @click="$router.go(-1)" >
          <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Detalles usuario
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editUser">
        <b-form
            class="mt-2"
            @submit.prevent="handleSubmit"
            @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col cols="12" class="mb-2" >
              <ImageDropzone
                  ref="images"
                  :files="files"
                  v-if="userPrepare"
              ></ImageDropzone>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-checkbox                
                  v-model="user.active"
                  :value="1"
                  class="d-inline-block mb-2 mt-1"
                  switch
                  inline
              >
                Estado usuario <span v-if="user.active == 1"> (Activado) </span><span v-else> (Desactivado) </span>
              </b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-group
                  :label="'Nombre'"
                  label-for="account-name"
              >
                <b-form-input
                    v-model="user.name"
                    name="name"
                    :placeholder="'Nombre'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6" >
              <b-form-group
                  :label="'Apellidos'"
                  label-for="account-username"
              >
                <b-form-input
                    v-model="user.surname"
                    :placeholder="'Apellidos'"
                    name="surname"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider
                  #default="{ errors }"
                  name="Email"
                  :rules="{required, email, emailUnique:id}"
              >
                <b-form-group
                    :label="'Email'"
                    label-for="account-e-mail"
                >
                  <b-form-input
                      v-model="user.email"
                      name="email"
                      :placeholder="'Email'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group
                  :label="'Telefono'"
                  label-for="account-phone"
              >
                <b-form-input
                    v-model="user.phone"
                    name="phone"
                    :placeholder="'Telefono'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                  :label="'Puesto de trabajo'"
                  label-for="account-job"
              >
                <b-form-input
                    v-model="user.job"
                    name="job"
                    :placeholder="'Puesto de trabajo'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider
                  #default="{ errors }"
                  :name="'Rol'"
                  rules="required"
              >
                <b-form-group
                    :label="'Rol'"
                    label-for="account-rols"
                >
                  <v-select
                      label="display_name"
                      :filterable="false"
                      :searchable="false"
                      :options="selectRoles"
                      placeholder="Selecciona un rol"
                      v-model="role"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  v-if="role && role.name === 'super_admin'"
                  v-model="user.notifications"
                  :value="true"
                  class="d-inline-block mb-2 mt-1"
              >
                Recibir notificaciones
              </b-form-checkbox>
            </b-col>
            <b-col sm="12">
              <b-form-group
                  label="Departamentos"
                  label-for="account-departments"
              >
                <b-row>
                  <b-col
                      sm="4"
                      v-if="item.active"
                      :key="item.id"
                      v-for="item in departmentsSelect"
                  >
                    <b-form-checkbox
                        v-model="departments"
                        :value="item.id"
                    >
                      {{ item.name }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                  type="submit"
                  variant="primary"
                  class="mt-2 mr-1"
              >
                {{ 'Enviar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BFormCheckbox, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      role: {},
      companies: '',
      departments: [],
      files: [],
      profileFile: null,
      required,
      email,
      userPrepare: false,
      changePassword: false,
    }
  },
  computed: {
    ...mapGetters({
      selectRoles: 'users/getRoles',
      user: 'users/getUser',
      selectCompanies: 'companies/getListCompanies',
      departmentsSelect: 'departments/getSelectDepartments',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'users/edit',
      getUser: 'users/getUser',
      selectDepartments: 'departments/selectDepartments',
      getRoles: 'users/getRoles',
    }),
    ...mapMutations({
      setSelectDepartments: 'departments/setSelectDepartments',
    }),
    setData() {
      if (this.user.avatar !== undefined && this.user.avatar !== null && this.user.avatar) {
        const avatar = {
          name: 'Avatar',
          url: this.user.avatar,
          urlDelete: `/users/delete_image/${this.user.id}`,
        }
        this.files = [avatar]
      }
      if (this.user.roles) {
        this.role = this.user.roles[0]
      }
      this.userPrepare = true
    },
    setClients() {
      if (this.user.clients) {
        this.clients = this.user.clients
      }
    },
    setDepartments() {
      if (this.user.departments[0]) {
        this.user.departments.forEach(element => {
          this.departments.push(element.id)
        })
      }
    },
    handleSubmit() {
      this.$refs.editUser.validate().then(success => {
        if (success) {
          const { id } = this.user
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, user: formData })
          }
        }
      })
    },
    createFormData() {
      const data = this.$refs.images.getFormData()
      if (this.departments.length > 0) {
        this.departments.forEach(element => {
          if (element && element !== null) {
            data.append('departments[]', element)
          }
        })
      } else {
        data.append('departments', '')
      }
      if (this.role) {
        data.append('role', this.role.name)
        if(this.role.name != 'super_admin'){
          data.append('notifications', false)
        }else{
          data.append('notifications', this.user.notifications)
        }
      }
      data.append('name', this.user.name)
      data.append('email', this.user.email)
      data.append('phone', this.user.phone)
      data.append('surname', this.user.surname)
      data.append('job', this.user.job)
      data.append('active', this.user.active)

      if (this.changePassword === true) {
        data.append('oldPassword', this.user.oldPassword)
        data.append('newPassword', this.user.newPassword)
        data.append('newPassword_confirmation', this.user.newPassword_confirmation)
      }

      return data
    },
  },
  async created() {
    await this.getUser(this.$route.params.id)
    await this.setData()
    await this.selectDepartments({})
    await this.setDepartments()
    await this.getRoles()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
